import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import EmployeeImg from "../../assests/images/dashboard/Text input 2.png";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import RetingImg from "../../assests/images/dashboard/kid_star.png";
import CardImg from "../../assests/images/dashboard/map.png";
import GroupImg from "../../assests/images/dashboard/group.png";
import DotIcon from "../../assests/images/dashboard/Text input container 2.png"
import Dropdown from "../Dropdown/EmployeeDropdown";


const EmployeeCard = ({ restaurant, handleRestaurantClick ,onSettingClick }: any) => {

  // const address = `${restaurant?.address?.addressLine1 || '--'}, ${restaurant?.address?.addressLine2 ? restaurant?.address?.addressLine2 + ', ' : ''}${restaurant?.address?.city}, ${restaurant?.address?.state}, ${restaurant?.address?.zipCode}`
  return (
    <div
    key={restaurant.correlationId}
    className="relative w-[267px] h-[154px] border-[#F1F1F2] border bg-white rounded-xl"
  >
    <div>
      <img
        className="h-[73px] w-[100%] rounded-t-xl cursor-pointer"
        src={
          restaurant.bucketKeyName
            ? restaurant.bucketKeyName
            : EmployeeImg
        }
        alt={restaurant.name}
        onClick={onSettingClick}
      />
    </div>
    <div
      className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
      // onClick={() => handleRestaurantClick(restaurant.correlationId)}
    >
      <Dropdown />
    </div>

    <div className="p-4 flex flex-col justify-center items-center gap-y-2">
        <h1 className="text-lg">{restaurant.name}</h1>
        <h1 className="text-xs text-[#828282]">{restaurant.name}</h1>
    </div>
  </div>
  );
};

export default EmployeeCard;
