import {configureStore} from '@reduxjs/toolkit';
import authSlice  from './features/auth-slice';
import restaurantSlice from './features/restuarant-slice';
import employeeSlice from './features/employee-slice';

const store = configureStore({
  reducer:{
    auth:authSlice,
    restaurant: restaurantSlice,
    employee: employeeSlice,
  }
})

export default store;