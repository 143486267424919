import moment from "moment";

export const convertMillisecondsToTime = (
  milliseconds: number,
  date = new Date()
) => {
  // Create a moment object for the given date
  const momentDate = moment(date).startOf("day");

  // Add the milliseconds to the start of the day
  const timeMoment = momentDate.add(milliseconds, "milliseconds");

  // Format as needed
  return timeMoment;
};

export const convertDateToMilliseconds = (date: any) => {
  const timeStr = moment(date).format("HH:mm");
  const hour = moment(timeStr, "HH:mm");
  return hour.hour() * 3600000 + hour.minute() * 60000;
};
