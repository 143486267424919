import React, { useState } from "react";
import { BtnLoader } from "../../Loader/index";
import { API_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { successToast } from "src/utils/toasts";
import { useNavigate } from "react-router-dom";

const ExistingConnectStripSection = ({ existingConnectedAccounts, toggleExistingAccountConnect, isSubscriptionTab = false }: any) => {
  const { axBe } = useAxiosInterceptor();
  const navigate = useNavigate();

  const onClickExistingConnect = async (account: any, index: number) => {
    try {
      const { detailInfo } = JSON.parse(
        localStorage.getItem("createRestaurantDetails") || "{}"
      );
      if (!detailInfo?.correlationId) return;
      toggleExistingAccountConnect(index, true);
      const response: any = await axBe.post(
        `${API_ENDPOINT}stripe/connectedAccount/storeConnectedAccount`,
        {
          storeCorrelationId: detailInfo?.correlationId,
          selectedConnectedAccount: account.connectedAccountId,
        }
      );
      if (response?.error) {
        throw response.error;
      }
      navigate("/my-restaurants");
      localStorage.removeItem("createRestaurantDetails");
      successToast("Restuarant has been Connected!");
    } catch (error) {
      console.error(error);
      toggleExistingAccountConnect(index, false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full flex-col">
      <div className="flex flex-col text-center mb-10 ">

          <h1 className="text text-xl text-center">
            We found existing connected accounts,
            <br /> would you like to use an existing account for this restaurant?
          </h1>
      </div>

      <div className={`bg-white border border-[#F1F1F2] ${isSubscriptionTab ? "w-[75%]" :"w-[60%]" } flex justify-center items-center rounded-2xl py-8`}>
        <div className="flex w-[92%]">
          {existingConnectedAccounts.map((account: any, index: number) => (
            <div
              key={index}
              className="flex justify-between items-center w-full"
            >
              <div className="flex flex-col">
                <p className="text-[#666666] text-lg font-bold">
                  {account.businessName}
                </p>
                <p className="text-[#666666]">
                  {account.supportAddress.line1}, {account.supportAddress.city},{" "}
                  {account.supportAddress.state},{" "}
                  {account.supportAddress.postalCode},{" "}
                  {account.supportAddress.country}
                </p>
              </div>
              <div>
                <div className="ml-4">
                  {account.inProgress ? (
                    <BtnLoader />
                  ) : (
                    <button
                   
                      className="text-sm duration-500 text-orange h-[38px] rounded-xl bg-white border border-[#F1F1F2]  px-4"
                      onClick={() => onClickExistingConnect(account, index)}
                    >
                      {'Connect'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExistingConnectStripSection;
