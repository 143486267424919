import * as React from "react";

interface SVGComponentProps {
  color?: string; // Optional color prop
}

const SVGComponent: React.FC<SVGComponentProps> = ({ color = "#828282", ...props }) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // Spread operator to allow passing additional props
  >
    <path
      d="M0.625 7.625V0.625H7.625V7.625H0.625ZM0.625 16.375V9.375H7.625V16.375H0.625ZM9.375 7.625V0.625H16.375V7.625H9.375ZM9.375 16.375V9.375H16.375V16.375H9.375ZM2.375 5.875H5.875V2.375H2.375V5.875ZM11.125 5.875H14.625V2.375H11.125V5.875ZM11.125 14.625H14.625V11.125H11.125V14.625ZM2.375 14.625H5.875V11.125H2.375V14.625Z"
      fill={color} // Use the color prop
    />
  </svg>
);

export default SVGComponent;
