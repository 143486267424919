import RestuarantImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import DeleteIcon from "../../assests/images/dashboard/delete.png";
import RetingImg from "../../assests/images/dashboard/kid_star.png";
import CardImg from "../../assests/images/dashboard/map.png";
import GroupImg from "../../assests/images/dashboard/group.png";
import { FRONTEND_URL } from "src/constants/endpoints";
import { S3_BASE_URL } from "src/constants/endpoints";



const RestaurantCard = ({ restaurant, handleRestaurantClick }: any) => {
  const changeModeToRestaurant = () => {
    localStorage.setItem("restaurant", JSON.stringify(restaurant));
    window.location.href = `${FRONTEND_URL}/my-restaurant/${restaurant.correlationId}/dashboard`;
  };
  const address = `${restaurant?.address?.addressLine1 || "--"}, ${
    restaurant?.address?.addressLine2
      ? restaurant?.address?.addressLine2 + ", "
      : ""
  }${restaurant?.address?.city}, ${restaurant?.address?.state}, ${
    restaurant?.address?.zipCode
  }`;
  return (
    <div
      key={restaurant.correlationId}
      className="relative w-[358px] h-[279px] border-[#F1F1F2] border bg-white rounded-xl"
    >
      <div>
        <img
          className="h-[173px] w-[100%] rounded-t-xl cursor-pointer"
          src={restaurant?.bucketKeyName ? restaurant?.bucketKeyName.includes('blob') ? restaurant?.bucketKeyName : S3_BASE_URL + restaurant?.bucketKeyName : RestuarantImg }
          alt={restaurant.name}
          onClick={changeModeToRestaurant}
        />
      </div>
      <div
        className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer"
        onClick={() => handleRestaurantClick(restaurant.correlationId)}
      >
        <img className="w-[12px] h-[13px]" src={DeleteIcon} alt="" />
      </div>

      <div className="p-4">
        <div className="flex justify-between mt-1">
          <h1>{restaurant.name}</h1>
          <div className="flex items-center gap-1">
            <img className="w-[15px] h-[14px]" src={RetingImg} alt="Rating" />
            <span>{restaurant.averageRating || "0.0"}</span>
            <span className="text-xs text-[#666666]">({restaurant.numberOfReviews || "0" })</span>
          </div>
        </div>

        <div className="flex items-center gap-2 mt-1">
          <img className="w-[15px] h-[15px]" src={CardImg} alt="Card" />
          <div className="tooltip">
            <p className="tooltip-section text-[#666666] text-sm">
              {address}
            </p>
            <span className="tooltip-text">{address}</span>
          </div>
        </div>

        <div className="flex items-center gap-1 mt-1">
          <img className="w-[19px] h-[14px]" src={GroupImg} alt="Group" />
          <p className="text-[#666666] text-sm">
            {restaurant?.employeeCount} Employees
          </p>
        </div>
      </div>
    </div>
  );
};

export default RestaurantCard;
