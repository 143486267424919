import { useCallback, useEffect, useState } from 'react';
import { useAuth } from './../contexts/auth-provider';
import { useNavigate } from 'react-router-dom';
import { failureToast } from './../utils/toasts';

const TIMEOUT = 60 * 60 * 1000; // 1 hour

export const useIdleTimer = () => {
  const { signOut } = useAuth();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setShouldNavigate(true);
    failureToast("No activity last 1 hour, you have been logged out");
  }, []);

  const resetTimer = useCallback(() => {
    clearTimeout((window as any).idleTimer);
    (window as any).idleTimer = setTimeout(logout, TIMEOUT);
  }, [logout]);

  useEffect(() => {
    // Set up event listeners for user activity
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keydown', resetTimer);
    document.addEventListener('keyup', resetTimer);
    document.addEventListener('scroll', resetTimer); // Added to track scroll activity
    document.addEventListener('click', resetTimer); // Added to track mouse clicks

    // Start the timer
    resetTimer();

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('keydown', resetTimer);
      document.removeEventListener('keyup', resetTimer);
      document.removeEventListener('scroll', resetTimer);
      document.removeEventListener('click', resetTimer);
      clearTimeout((window as any).idleTimer);
    };
  }, [resetTimer]);

  useEffect(() => {
    if (shouldNavigate) {
      signOut();
      navigate("/login", { replace: true });
      setShouldNavigate(false);
    }
  }, [shouldNavigate, navigate, signOut]);
};
