import React, { useState } from "react";
import BellIcon from "../../assests/images/dashboard/Notification.png";
import SettingIcon from "../../assests/images/dashboard/settings.png";
import Bellicon from "../../assests/images/dashboard/Button container.png";

const NotificationScilentDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  const toggleDropdown = (value?: string) => {
    setIsOpen(!isOpen);
    if (value) setValue(value);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <img
            aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => toggleDropdown()}
          className="w-[17px] h-[17px] cursor-pointer"
          src={SettingIcon}
          alt=""
        />
      </div>

      {isOpen && (
      <div
        className="absolute -right-4 -top-4  z-10 w-[30rem] origin-top-right rounded-2xl bg-white shadow-lg ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <div className="py-1 px-2" role="none">


          <div className="flex items-center justify-between px-4">
           <h1 className="text-lg font-semibold">Notification</h1>
           <img className="w-[17px] h-[17px] cursor-pointer" src={SettingIcon} alt="" />
          </div>

          <div className="flex items-center flex-col px-4 mt-8">
            <div>
              <img className="w-[64px] h-[64px]" src={Bellicon} alt="" />
            </div>
        
        <div className="text-center mt-4">
            <h1 className="text-lg">You don't have any notifications</h1>
            <p className="text-[#666666] text-sm mt-1">We'll notify you about important updates and any time you're mentioned on Eats Bits.</p>
        </div>
        <div className="flex justify-center my-8">
            <button
              className="hover:bg-orange text-sm hover:text-white duration-500 px-4 text-orange h-[35px] rounded-md bg-[#FD76571A]"
            >
              Notifications Setting
            </button>
          </div>

          
          </div>
        </div>
      </div>
       )} 
    </div>
  );
};

export default NotificationScilentDropDown;
