import { useState } from "react";
import LineChartBar from "src/components/LineChart/LineChart";
import AreaChartBar from "src/components/AreaChart/AreaChart";
import QrCodeIcon from "../../assests/images/dashboard/Union.png";
import DownloadIcon from "../../assests/images/dashboard/Container.png";
import EidtIcon from "../../assests/images/dashboard/edit vertical.png";
import UserIcon from "../../assests/images/dashboard/Switch container.png";
import Dropdown from "src/components/Dropdown/Dropdown";
import { useAuth } from "src/contexts/auth-provider";
import { Link, useNavigate } from "react-router-dom";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";

import GreenlineIcon from "../../assests/images/dashboard/Slider.png";
import RedlineIcon from "../../assests/images/dashboard/Slider 2.png";
import BluelineIcon from "../../assests/images/dashboard/Slider 3.png";
import { useDispatch, useSelector } from "react-redux";
import RestaurantCard from "src/components/RestaurantCard/RestaurantCard";
import { BtnLoader } from "src/Loader/index";
import { delete_restaurant_modal } from "src/store/features/restuarant-slice";

const employeesData = [
  { imageUrl: null, name: "Arlene McCoy", designation: "Senior waiter" },
  { imageUrl: null, name: "Guy Hawkins", designation: "Junior waiter" },
  { imageUrl: null, name: "Arlene McCoy", designation: "Senior waiter" },
  { imageUrl: null, name: "Arlene McCoy", designation: "Senior waiter" },
];
export const Dashboard = () => {
  const [employees, setEmployees] = useState(employeesData);
  const { hasRestaurant } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { restaurants } = useSelector(
    (state: any) => state.restaurant
  );
  const [isRestaurantLoading, setIsRestaurantLoading] = useState<boolean>(false);

  const handleRestaurantClick = (restaurantId: string) => {
    dispatch(delete_restaurant_modal(restaurantId));
  };
  return (
    <div className="h-full flex flex-col">
      <div>
        {!hasRestaurant ? (
          <></>
        ) : (
          <div className="flex justify-between items-center mt-4 mb-6">
            <div className="text-3xl font-semibold ml-3">{hasRestaurant?.name}</div>
            <div className="flex gap-3">
              <div className="flex justify-center">
                <button className="w-[200px] flex justify-center items-center gap-x-4 text-white h-[38px] rounded-xl bg-[#FD7657] duration-500">
                  <img className="h-[15px] w-[15px]" src={QrCodeIcon} alt="" />
                  Generate QR Code
                </button>
              </div>

              <div className="flex justify-center">
                <button className="w-[200px] flex justify-center items-center gap-x-4 text-[#FD7657] h-[38px] rounded-xl bg-[#FD76571A] duration-500">
                  <img
                    className="h-[15px] w-[15px]"
                    src={DownloadIcon}
                    alt=""
                  />
                  Download Client
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex w-full gap-6">
          <div className="w-[40%]">
            <div className="flex justify-between p-3">
              <h1 className="font-semibold text-xl">Total Income</h1>
              <Dropdown />
            </div>

            <div className="h-[347px]  rounded-2xl bg-white p-6">
              <h1 className="text-3xl">
                $<span>79,34 </span>
              </h1>
              <p className="text-[#666666] text-sm my-4">Sales Over Time</p>
              <AreaChartBar />
            </div>
          </div>
          <div className="w-[60%]">
            <div className="flex justify-between p-3">
              <h1 className="font-semibold text-xl">Income</h1>
              <Dropdown />
            </div>

            <div className="h-[347px] rounded-2xl bg-white p-4 flex gap-4 flex-col">
              <div className="flex flex-row gap-10">
                <div className="">
                  <h1 className="">
                    <span className="text-3xl">72</span>
                  </h1>
                  <div className="flex justify-center items-center gap-x-1">
                    <div className="mt-[8px]">
                      <img
                        className="w-[4px] h-[12px]"
                        src={GreenlineIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-[#666666] text-sm mt-2">Total order</p>
                  </div>
                </div>
                <div>
                  <h1 className="text-3xl">
                    $<span>976,34</span>
                  </h1>
                  <div className="flex justify-center items-center gap-x-1">
                    <div className="mt-[8px]">
                      <img
                        className="w-[4px] h-[12px]"
                        src={RedlineIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-[#666666] text-sm mt-2">Avg. Check</p>
                  </div>
                </div>
                <div>
                  <h1 className="">
                    <span className="text-3xl"> 94</span>
                  </h1>
                  <div className="flex justify-center items-center gap-x-1">
                    <div className="mt-[8px]">
                      <img
                        className="w-[4px] h-[12px]"
                        src={BluelineIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-[#666666] text-sm mt-2">Avg. Visitors</p>
                  </div>
                </div>
                <div></div>
              </div>
              <LineChartBar />
            </div>
          </div>
        </div>
      </div>

      {!hasRestaurant ? (
        isRestaurantLoading ? (
          <div className="flex w-full h-[100vh] justify-center mt-20">
            <BtnLoader />
          </div>
        ) : !restaurants?.length ? (
          <div className="mt-4">
          <div className="flex justify-between p-3">
            <h1 className="font-medium text-xl">My Restaurants</h1>
            <p 
            className="text-orange cursor-pointer">View All</p>
          </div>
          <div className="h-[347px]  rounded-2xl bg-white p-6 flex justify-center items-center flex-col">
            <h1>You have no restaurants yet</h1>
            <p className="text-[#9B9B9B]">
              Click "Add new restaurants" to interact with it
            </p>
            <div className="flex justify-center my-4">
              <Link className="w-[100%]" to="/create-restaurant">
                <button className="w-[200px] text-white h-[38px] rounded-xl bg-[#FD7657] hover:bg-white hover:text-orange duration-500">
                  &#65291; Add Restuarant
                </button>
              </Link>
            </div>
          </div>
        </div>
        ) : (
          <div>
            <div className="flex justify-between p-3 mt-4">
              <h1 className="font-semibold text-xl">My Restaurants</h1>
              <p
              onClick={() => navigate("/my-restaurants")}
              className="text-orange cursor-pointer">View All</p>
            </div>
            <div className="flex gap-3">
              <div
                className="w-[10%] bg-white rounded-xl flex justify-center items-center cursor-pointer"
                onClick={() => navigate("/create-restaurant")}
              >
                <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
              </div>
              {restaurants?.slice(0 ,3).map((restaurant: any, index : any) => (
                <RestaurantCard key={index} restaurant={restaurant} handleRestaurantClick={handleRestaurantClick} />
              ))}
            </div>
          </div>
        )
      ) : (
        <div className="flex w-full gap-8">
          <div className="w-[70%] mt-6">
            <div className="flex justify-between p-3">
              <h1 className="font-semibold text-xl">Recent Orders</h1>
              <p className="text-orange cursor-pointer">View All</p>
            </div>
            <div className=" rounded-2xl bg-white p-6">
              <div className="border-b border-[#EDEDED] flex  flex-col gap-y-4">
                <div className="flex justify-between ">
                  <div className="flex items-center gap-4">
                    <h1 className="font-semibold">DD945632</h1>
                    <div className="bg-[#F0C0451A] rounded-lg px-5 py-1">
                      <h1 className="text-[#EAB42A] text-sm">Pending</h1>
                    </div>
                  </div>
                  <div>
                    <img className="h-[20px] w-[20px]" src={EidtIcon} alt="" />
                  </div>
                </div>
                <div className="flex gap-3">
                  <p className="text-[#252525] text-sm">
                    Nourish Combo (Sushi, Cola, Ramen)
                  </p>
                  <p className="text-[#252525] text-sm">$25,14</p>
                  <p className="text-[#252525] text-sm">3 items</p>
                  <p className="text-[#252525] text-sm">May 29 at 7:54 AM</p>
                  <p className="text-[#252525] text-sm">John Doe</p>
                  <p className="text-[#252525] text-sm">Pickup #32322</p>
                </div>
                <div className="p-2">
                  <p className="text-[#8A8A8A] text-sm">
                    Allergic to mushrooms and mojitos without alcohol
                  </p>
                </div>
              </div>
              <div className="border-b border-[#EDEDED] flex  flex-col gap-y-4 mt-4">
                <div className="flex justify-between ">
                  <div className="flex items-center gap-4">
                    <h1 className="font-semibold">DD945632</h1>
                    <div className="bg-[#3ACC481A] rounded-lg px-5 py-1">
                      <h1 className="text-[#3ACC48] text-sm">Complete</h1>
                    </div>
                  </div>
                  <div>
                    <img className="h-[20px] w-[20px]" src={EidtIcon} alt="" />
                  </div>
                </div>
                <div className="flex gap-3">
                  <p className="text-[#252525] text-sm">
                    Nourish Combo (Sushi, Cola, Ramen)
                  </p>
                  <p className="text-[#252525] text-sm">$25,14</p>
                  <p className="text-[#252525] text-sm">3 items</p>
                  <p className="text-[#252525] text-sm">May 29 at 7:54 AM</p>
                  <p className="text-[#252525] text-sm">John Doe</p>
                  <p className="text-[#252525] text-sm">Pickup #32322</p>
                </div>
                <div className="p-2">
                  <p className="text-[#8A8A8A] text-sm">
                    Allergic to mushrooms and mojitos without alcohol
                  </p>
                </div>
              </div>
              <div className="border-b border-[#EDEDED] flex  flex-col gap-y-4 mt-4">
                <div className="flex justify-between ">
                  <div className="flex items-center gap-4">
                    <h1 className="font-semibold">DD945632</h1>
                    <div className="bg-[#F144451A] rounded-lg px-5 py-1">
                      <h1 className="text-[#F14445] text-sm">Error</h1>
                    </div>
                  </div>
                  <div>
                    <img className="h-[20px] w-[20px]" src={EidtIcon} alt="" />
                  </div>
                </div>
                <div className="flex gap-2">
                  <p className="text-[#252525] text-sm">
                    Nourish Combo (Sushi, Cola, Ramen)
                  </p>
                  <p className="text-[#252525] text-sm">$25,14</p>
                  <p className="text-[#252525] text-sm">3 items</p>
                  <p className="text-[#252525] text-sm">May 29 at 7:54 AM</p>
                  <p className="text-[#252525] text-sm">John Doe</p>
                  <p className="text-[#252525] text-sm">Pickup #32322</p>
                </div>
                <div className="p-2">
                  <p className="text-[#8A8A8A] text-sm">
                    Allergic to mushrooms and mojitos without alcohol
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[30%] mt-6">
            <div className="flex justify-between items-center p-3">
              <h1 className="font-semibold text-xl">Employee</h1>
              <p className="text-orange cursor-pointer">View All</p>
            </div>
            <div className="rounded-2xl bg-white p-6 flex gap-4 flex-col">
              {employees.map((employee, index) => (
                <div className="flex gap-x-4" key={index}>
                  <div className="">
                    <img className="h-[42px] w-[50px]" src={UserIcon} alt="" />
                  </div>
                  <div
                    className="border-b border-[#EDEDED] w-full pb-2"
                    key={index}
                  >
                    <h1 className="text-sm">{employee?.name}</h1>
                    <h1 className="text-sm  mt-1">{employee.designation}</h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
