import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../contexts/auth-provider";
import routesForPublic from "../utils/public-routes";
import routesForAuthenticatedOnly from "../utils/authenticated-routes";
import routesForNotAuthenticatedOnly from "../utils/nonAuthenticated-routes";
import PreviousLandingPage from "../pages/landing-page/previous-landing-page";
import {DefaultLayout} from "../layout/DefaultLayout";
import { Dashboard } from "src/pages/dashboard/dashboard";


// Create a fallback route component to handle redirections based on authentication status
const FallbackRoute = () => {
  const { token, user } = useAuth();
  const isAuthorized = !!token && Object.keys(user || {}).length > 0;
  const location = useLocation();

  if (!isAuthorized && location.pathname === "/login") {
    return <Navigate to="/login" replace />;
  }
  if (
    isAuthorized &&
    (location.pathname === "/login" || location.pathname === "/")
  ) {
    return <Navigate to="/dashboard" replace />;
  }
  return <Navigate to="/login" replace />;
};

const Routes: React.FC = () => {
  const { token, user } = useAuth();
  const isAuthorized = !!token && Object.keys(user || {}).length > 0;
  const publicRoute = [
    ...routesForPublic,
    {
      path: "/",
      element: isAuthorized ? <DefaultLayout /> : <PreviousLandingPage />,
      children: [
        {
          path: "/",
          element: isAuthorized ? <Dashboard /> : <PreviousLandingPage />,
        },
      ],
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const routes = [
    ...publicRoute,
    ...(isAuthorized
      ? routesForAuthenticatedOnly
      : routesForNotAuthenticatedOnly),
  ];

  // Create router with routes
  const router = createBrowserRouter([
    ...routes,
    {
      path: "*",
      element: <FallbackRoute />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
