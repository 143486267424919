import { gql } from "@apollo/client";

export const SIGNIN_STORE_USER = `query storeUserSignIn($storeUserSignInInput: StoreUserSignInInput!) {
  storeUserSignIn(storeUserSignInInput: $storeUserSignInInput) {
    accessToken
    refreshToken
    type
    roles
    storeUser{
      correlationId
      firstName
      lastName
      email
      phoneNumber
      employerName
      stores{
      correlationId
      name
      nonEnglishName
      primaryContactPhoneNumber
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      style
      employeeCount
      connectedAccountId
      bucketKeyName
      categories {
        correlationId
        name
      }
      operationHours {
        day
        startHour
        endHour
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
        employeeCount
        averageRating
      }
    }
  }
}`;

export const REGISTER_STORE_USER = `mutation registerStoreUser($createStoreUserInput: CreateStoreUserInput!) {
    registerStoreUser(createStoreUserInput: $createStoreUserInput) {
        correlationId
        firstName
        lastName
        email
        phoneNumber
        employerName
    }
}`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      requestProcessed
    }
  }
`;

export const SAVE_NEW_PASSWORD = gql`
  mutation saveNewPassword($saveNewPasswordInput: SaveNewPasswordInput!) {
    saveNewPassword(saveNewPasswordInput: $saveNewPasswordInput) {
      message
    }
  }
`;

export const ACTIVATE_USER_ACCOUNT = gql`
  mutation activateUserAccount(
    $activateUserAccountInput: ActivateUserAccountInput!
  ) {
    activateUserAccount(activateUserAccountInput: $activateUserAccountInput) {
      activated
      message
    }
  }
`;

export const CREATE_RESTAURANT = `mutation createStore($createStoreInput: CreateStoreInput!) {
  createStore(createStoreInput: $createStoreInput) {
    name
    correlationId
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
    operationHours {
      day
      startHour
      endHour
    }
  }
}
`;

export const UPDATE_RESTAURANT = `mutation updateStore($updateStoreInput: UpdateStoreInput!) {
  updateStore(updateStoreInput: $updateStoreInput) {
    correlationId
    name
    nonEnglishName
    primaryContactFirstName
    primaryContactLastName
    primaryContactEmail
    primaryContactPhoneNumber
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
    operationHours {
      day
      startHour
      endHour
    }
  }
}
`;

export const UPLOAD_RESTAURANT_IMAGE = `mutation createUploadPreSignedUrlForStore($createStorePreSignedUrlInput: CreateStorePreSignedUrlInput!) {
  createUploadPreSignedUrlForStore(createStorePreSignedUrlInput: $createStorePreSignedUrlInput) {
    preSignedUrl
  }
}`;

export const REFRESH_ACCESS_TOKEN = `mutation refreshAccessToken($refreshAccessTokenInput: RefreshAccessTokenInput!){
    refreshAccessToken(refreshAccessTokenInput: $refreshAccessTokenInput) {
			accessToken
    	refreshToken
    }
}`;

export const CREATE_REVIEW = `mutation createReview($createReviewInput: CreateReviewInput!) {
  createReview(createReviewInput: $createReviewInput) {
    createdAt
    correlationId
    storeCorrelationId
    content
    authorId
    replyTo
    rating
  }
}`;
