import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SkeletonLoader() {
    return (
        <tr className="border-t border-[#EBF5FF] flex justify-between w-full">
            <td className="p-4 text-sm">
                <Skeleton width={100} />
            </td>
            <td className="p-4 text-sm">
                <Skeleton width={200} />
            </td>
            <td className="p-4 text-sm font-semibold">
                <Skeleton width={80} />
            </td>
            <td className="p-4 text-sm">
                <Skeleton width={100} />
            </td>
        </tr>
    )
}
export default SkeletonLoader
