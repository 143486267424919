import React from "react";
import CrossIcon from "../../assests/images/dashboard/Vector 3.png";

const inviteEmployeeModal = () => {
  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-[9999] overflow-y-auto ">
        <div className=" flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className=" w-[529px] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex flex-col">
             
            <div className="rounded-2xl bg-white p-6">
            <div className="flex justify-between">
       
               <h1 className="font-semibold text-lg">Invite New Employer</h1>
               
              <img className="w-[12px] h-[12px]" src={CrossIcon} alt="" />
            
            </div>

            <div className="mt-4">
              <span className="text-[#9B9B9B] text-sm">Employer name *</span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="text"
                name="EmployerName"
                id="EmployerName"
                placeholder=""
                // value={formik.values.EmployerName}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
              />
              {/* {formik.touched.EmployerName && formik.errors.EmployerName ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.EmployerName}
                </div>
              ) : null} */}

            </div>
            <div className="mt-4">
              <span className="text-[#9B9B9B] text-sm">Employer position *</span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="text"
                name="EmployerPosition"
                id="EmployerPosition"
                placeholder=""
                // value={formik.values.EmployerPosition}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
              />
              {/* {formik.touched.EmployerPosition && formik.errors.EmployerPosition ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.EmployerPosition}
                </div>
              ) : null} */}

            </div>
            <div className="mt-4">
              <span className="text-[#9B9B9B] text-sm">Personal password *</span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="password"
                name="PersonalPassword"
                id="PersonalPassword"
                placeholder=""
                // value={formik.values.PersonalPassword}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
              />
              {/* {formik.touched.PersonalPassword && formik.errors.PersonalPassword ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.PersonalPassword}
                </div>
              ) : null} */}

            </div>
            <div className="mt-4">
              <span className="text-[#9B9B9B] text-sm">Email *</span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="email"
                name="email"
                id="email"
                placeholder=""
                // value={formik.values.email}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
              />
              {/* {formik.touched.email && formik.errors.email ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.email}
                </div>
              ) : null} */}

            </div>
            <div className="mt-4">
              <span className="text-[#9B9B9B] text-sm">Phone number *</span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="number"
                name="phoneNumber"
                id="phoneNumber"
                placeholder=""
                // value={formik.values.phoneNumber}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
              />
              {/* {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.phoneNumber}
                </div>
              ) : null} */}

            </div>
            <div className="mt-4">
              <span className="text-[#9B9B9B] text-sm">Restaurant Name</span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="text"
                name="restaurantName"
                id="restaurantName"
                placeholder=""
                // value={formik.values.restaurantName}
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
              />
              {/* {formik.touched.restaurantName && formik.errors.restaurantName ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.restaurantName}
                </div>
              ) : null} */}

            </div>
          </div>
          <div className="flex justify-end my-4 mr-6">
            <button
              className="hover:bg-white text-sm hover:text-orange duration-500 w-[100px] text-white h-[35px] rounded-md bg-[#FD7657]"
            >
               Send Invite
            </button>
          </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inviteEmployeeModal;
