import React from 'react'
import SearchForm from 'src/components/Searchform/SearchFrom'
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {delete_restaurant_modal,} from "src/store/features/restuarant-slice";
import {BtnLoader} from "src/Loader/index";
import EmployeeCard from 'src/components/EmployeeCard/Employee';


const Employee = () => {

  const navigate = useNavigate();
  const { filteredRestaurants } = useSelector(
    (state: any) => state.restaurant
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleRestaurantClick = (restaurantId: string) => {
    dispatch(delete_restaurant_modal(restaurantId));
  };

  return (
    <>
       <div className="flex justify-between items-center">
        <div>
          <SearchForm  placeholder="Search by name..."/>
        </div>
        <div>
          <div className="flex justify-center my-4">
            <button
              className="hover:bg-white hover:text-orange duration-500 w-[200px] text-white h-[42px] rounded-xl bg-[#FD7657]"
              onClick={() => navigate("/create-restaurant")}
            >
              &#65291; Add Restaurant
            </button>
          </div>
        </div>
      </div>

      <div className="flex gap-6 flex-wrap">
        {isLoading ? (
          <div className="flex w-full h-[100vh] justify-center mt-10">
            <BtnLoader />
          </div>
        ) : !filteredRestaurants?.length ? (
          <div className="w-[100%] flex justify-center items-center mt-5 text-[#666666] text-sm">
            <span>No restuantant found</span>
          </div>
        ) : (
          filteredRestaurants.map(
              (restaurant: any, index: any) => (<EmployeeCard key={index} restaurant={restaurant} handleRestaurantClick={handleRestaurantClick} />)
          )
        )}
      </div>
    </>
  )
}

export default Employee
