import RightHeaderSection from "./RightHeaderSection";

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  user: any;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <header className="sticky z-[999] top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex h-[81px] flex-grow border-b-[#F1F1F2] border-b items-center justify-between px-4 shadow-2 md:px-6 2xl:px-11">

        <div className="hidden sm:block">
          <div className="relative">
            <h1 className="font-medium text-2xl mt-3">Dashboard</h1>
          </div>
        </div>

        <div className="flex items-center gap-3 2xsm:gap-7">
          <RightHeaderSection user={props?.user} />
        </div>
      </div>
    </header>
  );
};

export default Header;
