import React, { useState } from "react";
import DotIcon from "../../assests/images/dashboard/Text input container 2.png"
import DeleteIcon from "../../assests/images/dashboard/delete 2.png"
import EditIcon from "../../assests/images/dashboard/edit.png"


const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');

  const toggleDropdown = (value?: string) => {
    setIsOpen(!isOpen);
    if (value) setValue(value);
  };
  


  return (
    <div className="relative inline-block text-left">
      <div>
        <img 
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => toggleDropdown()}
        className="h-[35px] w-[35px]" src={DotIcon} alt="" />
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-2xl bg-white shadow-lg ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1 px-2" role="none" >
            
<div className="flex items-center px-4 bg-[#F1F1F2] rounded-3xl">
            <div>
              <img className="w-[15px] h-[15px]" src={EditIcon} alt="" />
            </div>
            <button
              className="block w-full px-4 py-2 text-left text-sm text-[#828282]"
              role="menuitem"
              // onClick={() => toggleDropdown('Today')}
            >
              Edit
            </button>
            </div>
<div className="flex items-center px-4 mt-1">
            <div>
              <img className="w-[14px] h-[15px]" src={DeleteIcon} alt="" />
            </div>
            <button
              className="block w-full px-4 py-2 text-left text-sm text-[red]"
              role="menuitem"
              // onClick={() => toggleDropdown('Today')}
            >
              Delete
            </button>
            </div>
           
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;