// import LandingPage from "../pages/landing-page";
import PreviousLandingPage from "../pages/landing-page/previous-landing-page";
import { Navigate } from 'react-router-dom';

const routesForPublic: any = [
    // {
    //   path: "*",
    //   element: <NotFound/>
    // },
  ];

export default routesForPublic;
