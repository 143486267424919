import { useState } from "react";
import stripeImg from "../../assests/images/dashboard/stripeImage.png";
import { BtnLoader } from "../../Loader/index";
import { API_ENDPOINT } from "src/constants/endpoints";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";

const ConnectStripSection = ({
  hasConnectedId,
  isSubscriptionTab = false,
}: any) => {
  const [inProgress, setInProgress] = useState(false);
  const { axBe } = useAxiosInterceptor();
  const { user } = useAuth();

  const onClickConnect = async () => {
    try {
      setInProgress(true);
      const response: any = axBe.post(
        `${API_ENDPOINT}stripe/connectedAccount/account`,
        {
          email: user.storeUser.email,
        }
      );
      const { account, error } = response?.data;
      if (account) {
        connectAccountLink(account);
      }
      if (error || response?.error) {
        throw error || response.error;
      }
    } catch (error) {
      console.error(error);
      setInProgress(false);
    }
  };

  const connectAccountLink = async (account: string) => {
    try {
      setInProgress(true);
      const response = await axBe.post(
        `${API_ENDPOINT}stripe/connectedAccount/accountLink`,
        {
          account,
        }
      );
      const { data } = response;
      if (!data) {
        throw new Error("No data returned from API");
      }

      const { url, error } = data;
      if (url) {
        window.location.href = url;
      } else if (error) {
        throw new Error(error);
      }
    } catch (error) {
      setInProgress(false);
      console.error("Error connecting account:", error);
    }
  };

  return (
    <div
      className={`flex justify-center items-center w-full ${
        isSubscriptionTab ? "h-[16vh]" : "h-[50vh]"
      } flex-col`}
    >
      <div className="flex flex-col text-center mb-10 ">
        <h1 className="text text-2xl">Connect a payment processor</h1>
        <p className="mt-2">
          All payment processors are free to set up and take about 5 minutes to
          connect.
        </p>
      </div>

      <div
        className={`bg-white border border-[#F1F1F2] ${
          isSubscriptionTab ? "w-[75%] px-4" : "w-[60%]"
        } flex justify-center items-center rounded-2xl py-8`}
      >
        <div className="flex flex-col w-[80%]">
          <div>
            <img src={stripeImg} alt="" />
          </div>
          <p className="text-[#666666] mt-6">
            Connect Stripe to let customers pay with all major credit cards,
            Apple Pay and Afterpay/Clearpay.
          </p>
        </div>

        <div>
          <div className="ml-4">
            {inProgress ? (
              <BtnLoader />
            ) : (
              <button
                className="w-[150px] hover:bg-orange text-sm hover:text-white duration-500 text-orange h-[38px] rounded-xl bg-white border border-[#F1F1F2] px-4"
                onClick={() =>
                  hasConnectedId
                    ? connectAccountLink(hasConnectedId)
                    : onClickConnect()
                }
              >
                {hasConnectedId ? "Edit Account" : "Connect"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectStripSection;
