import React from 'react';
import { BtnLoader } from "../../commonLoader/index";


const CreateRestuarantFooter = ({ onCancel, isDisabled, onContinue, primaryTextButton, isLoading ,isLoadingBack }: any) => {

  return (
    <div className="flex items-center justify-center gap-2 fixed bottom-0 p-4 bg-white w-full z-50">
    <div className="flex justify-center mx-1">
      {
        onCancel && 
        <button
          onClick={onCancel}
          className="w-[117px] h-[40px] rounded-xl bg-[#F5F5F6] text-sm hover:bg-[#F5F5F6] hover:text-orange duration-500"
        >
          {isLoadingBack ? <BtnLoader /> : "Cancel"}
        </button>
      }
    </div>

    <div className="flex justify-center mx-1">
      <button
        type="button"
        className={`w-[117px] h-[40px] text-white rounded-xl bg-orange text-sm hover:bg-white hover:text-orange duration-500 ${
          isDisabled && "opacity-20"
        }`}
        onClick={onContinue}
        disabled={isDisabled}
      >
        {isLoading ? <BtnLoader /> : primaryTextButton || 'Continue'}
      </button>
    </div>
  </div>
  );
};

export default CreateRestuarantFooter;
