import React, { useEffect } from "react";
import { useParams} from 'react-router-dom';
import SuccessCard from "src/components/SuccessCard/SuccessCard";
import { useNavigate } from "react-router-dom";
import { successToast } from "src/utils/toasts";

export default function Return() {
  const {connectedAccountId} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    successToast('Your account has been connected successfully');
    navigate('/my-restaurants');
  });
  
  const onHanldeDone = () => {
    navigate('/my-restaurants');
  }

  return (
    <></>
    // <SuccessCard onHanldeDone={onHanldeDone} heading="Your subcription has been enabled successfully" subHeading="We have sent you a confirmation code to your email address" />
  );
}