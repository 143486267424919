import React from "react";
import { NavLink } from "react-router-dom";
import MenuSideLine from "../../assests/images/dashboard/01.png";
import ArrowUp from "../../assests/images/dashboard/chevron_forward (1).png";
import ArrowDown from "../../assests/images/dashboard/chevron_forward.png";
import SideActiveIcon from "../../assests/images/dashboard/Text input 7.58.55 PM.png";
import { useAuth } from "src/contexts/auth-provider";

const SidebarItem = ({
  index,
  route,
  isSubRoute,
  sidebarOpen,
  hasDropdown,
  toggleMenu,
  isSubMenuOpen,
}: any) => {
  const { hasRestaurant } = useAuth();
  return (
    <li key={index}>
      <NavLink
        to={typeof(route?.url) === 'string' ? route?.url : route?.url(hasRestaurant?.correlationId)}
        className={({ isActive }) => {
          const activeClass = `${
            isSubRoute ? "h-[40px] ml-7 relative" : "h-[57px]"
          } text-[#828282] group relative flex items-center gap-2.5 rounded-md px-[1.2rem] pr-[${
            sidebarOpen ? "4rem" : "0rem"
          }] font-medium duration-300 ease-in-out hover:bg-[#ffe2db81] `;
          return `${activeClass} ${
            isActive ? "!bg-[#ffe2db81] !text-orange" : ""
          }`;
        }}
        
        onClick={() => toggleMenu && toggleMenu(index)}
      >
        {({ isActive }) => (
          <>
           {isActive ? <div className="absolute left-[-16px]"><img className="w-[3px] h-[24px]" src={SideActiveIcon} alt="" /></div>:<></> }
            {isSubRoute ? (
              <div className="absolute bottom-[18px] left-[-10px]">
                <img className="w-[20px] h-[60px]" src={MenuSideLine} alt="" />
              </div>

            ) : (
              <></>
            )}
            {route?.icon && (
              <>       
              <span className={isSubRoute ? "text-xs" : ""}>
                <route.icon color={isActive ? "#FD7657" : "#828282"} />
              </span>
              </>
            )}
            {sidebarOpen && (
              <>
              <span className={isSubRoute ? "text-sm" : "ml-2"}>
                {route?.name}
              </span>
     </>
            )}

            {sidebarOpen && hasDropdown && (
              <div>
                <img
                  className="w-[15px] h-[8px] ml-20"
                  src={isActive ? ArrowUp : ArrowDown}
                />
              </div>
            )}
          </>
        )}
      </NavLink>
    </li>
  );
};

export default SidebarItem;
