import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '1:00 AM',
    uv: 430,
    pv: 224,
    amt: 240,
  },
  {
    name: '4:00 AM',
    uv: 552,
    pv: 139,
    amt: 221,
  },
  {
    name: '7:00 AM',
    uv: 100,
    pv: 98,
    amt: 229,
  },
  {
    name: '10:00 AM',
    uv: 280,
    pv: 600,
    amt: 200,
  },
  {
    name: '1:00 PM',
    uv: 190,
    pv: 400,
    amt: 281,
  },
  {
    name: '4:00 PM',
    uv: 290,
    pv: 380,
    amt: 250,
  },
  {
    name: '7:00 PM',
    uv: 340,
    pv: 430,
    amt: 210,
  },
  {
    name: '10:00 PM',
    uh: 340,
    pv: 200,
    amt: 400,
  }
];

const LineChartBar = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={500}
          data={data}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <CartesianGrid strokeDasharray="2 2" /> */}
          {/* <Legend /> */}
          <Line type="monotone" dataKey="pv" stroke="#B1E71D" strokeWidth={2}  />
          <Line type="monotone" dataKey="uv" stroke="#FD7657" strokeWidth={2} />
          <Line type="monotone" dataKey="amt" stroke="#8569F6" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
  );
};

export default LineChartBar;
