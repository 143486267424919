import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function FormSkeletonLoader() {
    return (
        <>

        <div className="">

            <div className="rounded-2xl bg-white  flex gap-4 items-center">
        <Skeleton circle={true} height={61} width={61} />
        <div className="flex flex-col">
          <Skeleton width={120} height={40} />
        </div>
            </div>
            <span className="text-[#9B9B9B] text-sm">Restaurant Name</span>
            <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg -[#F1F1F2] mt-1 px-2">
                <Skeleton height={35} />
            </div>
            <div className="flex flex-col mt-2">
                <div className="flex justify-between gap-x-4">
                    <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">Street</span>
                        <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg mt-1 px-2">
                            <Skeleton height={35} />
                        </div>
                       
                    </div>
                    <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">City</span>
                        <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg  mt-1 px-2">
                            <Skeleton height={35} />
                        </div>
                    
                    </div>
                </div>

                <div className="flex justify-between gap-x-4 mt-2">
                    <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">State/Province</span>
                        <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg  mt-1 px-2">
                            <Skeleton height={35} />
                        </div>
                       
                    </div>
                    <div className="w-full">
                        <span className="text-[#9B9B9B] text-sm">Zip code</span>
                        <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg  mt-1 px-2">
                            <Skeleton height={35} />
                        </div>
                      
                    </div>
                </div>
            </div>
            <h1 className="mt-6">Contact Information</h1>
            <div className="flex justify-between gap-x-4 mt-2">
                <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">First Name</span>
                    <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg  mt-1 px-2">
                        <Skeleton height={35} />
                    </div>
                </div>
                <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Last Name</span>
                    <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg  mt-1 px-2">
                        <Skeleton height={35} />
                    </div>
                </div>
            </div>
            <div className="flex justify-between gap-x-4 mt-4">
                <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Contact Number</span>
                    <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg  mt-1 px-2">
                        <Skeleton height={35} />
                    </div>
                </div>
                <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Email Address</span>
                    <div className="text-xs text-[#9B9B9B] h-[40px] w-full  rounded-lg xs mt-1 px-2">
                        <Skeleton height={35} />
                    </div>
                   
                </div>
            </div>
        </div>
        </>
        
    );
}

export default FormSkeletonLoader;
