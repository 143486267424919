import { ReactNode } from "react";
import {DefaultLayout} from "../layout/DefaultLayout";
import { Dashboard } from "../pages/dashboard/dashboard";

import { UploadImageRestaurant } from "../pages/restaurant-page/create-restaurant/UploadImageRestaurant";
import CreateRestaurant from "../pages/restaurant-page/create-restaurant/CreateRestaurant"
import Plans from "../pages/restaurant-page/create-restaurant/Plans";
import Success from "../pages/restaurant-page/success/Success";
import Cancel from "../pages/restaurant-page/cancel/Cancel";
import Refresh from "src/pages/restaurant-page/refresh/Refresh";
import Return from "src/pages/restaurant-page/return/Return";
import QrCodeModal from "src/components/QrCodeModal/QrCodeModal";
import DeleteRestaurant from "src/components/DeleteRestaurant/DeleteRestaurant";
import ShowTokenRefreshModal from "src/components/ShowTokenRefreshModal/ShowTokenRefreshModal";
import {RestaurantPage} from "../pages/restaurant-page/RestaurantPage";
import RestaurantSetting from "../pages/Restaurant-setting/RestaurantSetting";
import Employee from "src/pages/Employee/Employee";
import InviteEmployee from "../components/InviteEmployeeModal/InviteEmployeeModal";
import Menu from "src/pages/Menu/Menu";
import RecentOrders from "src/pages/RecentOrders/RecentOrders";
import UserProfileAccount from "src/pages/UserProfileAccount/UserProfileAccount";
import SubcriptionSetting from "src/components/SubcriptionSetting/SubcriptionSetting";

export interface RouteInterface {
  path: string;
  element: ReactNode; // ReactNode can be used for JSX elements
  children?: RouteInterface[]; // Nested routes
}

const routesForAuthenticatedUser: RouteInterface[] = [
  {
    path: "/",
    element: <DefaultLayout />,

    children: [
      {
        path: "dashboard",
        element: <Dashboard/>
      },
      {
        path: "my-restaurant/:restaurantCorrelationId/dashboard",
        element: <Dashboard/>
      },
      {
        path: "my-restaurants",
        element: <RestaurantPage/>
      },
      {
        path: "restaurant-setting",
        element: <RestaurantSetting/>
      },
      {
        path: "create-restaurant",
        element: <CreateRestaurant/>
      },
      {
        path: "upload-image",
        element: <UploadImageRestaurant />
      },
      {
        path: "plans",
        element: <Plans/>
      },
      {
        path: "stripe-connection",
        element: <Plans/>
      },
      {
        path: "cancel",
        element: <Cancel/>
      },
      {
        path: "success",
        element: <Success/>
      },
      {
        path: "connectedAccount/refresh/:connectedAccountId",
        element: <Refresh />,
      },
      {
        path: "connectedAccount/return/:connectedAccountId",
        element: <Return />,
      },
      {
        path: "qr-code",
        element: <QrCodeModal />,
      },
      {
        path: "delete-restaurant",
        element: <DeleteRestaurant />,
      },
      {
        path: "show-token-refresh-modal",
        element: <ShowTokenRefreshModal />,
      },
      {
        path: "employee",
        element: <Employee />,
      },
      {
        path: "menu",
        element: <Menu />,
      },
      {
        path: "invite-employee",
        element: <InviteEmployee />,
      },
      {
        path: "recent-oreders",
        element: <RecentOrders />,
      },
      {
        path: "user-profile-account",
        element: <UserProfileAccount />,
      },
      {
        path: "subcription-setting",
        element: <SubcriptionSetting />,
      },
    ],
  },
];

export default routesForAuthenticatedUser;
