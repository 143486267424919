import React, { useState } from 'react';
import RestaurantSettingComponent from "../../components/RestaurantSetting/RestaurantSettingComponent";
import SubcriptionSettinf from "../../components/SubcriptionSetting/SubcriptionSetting";
import ConnectedAccount from 'src/components/ConnectedAccount/ConnectedAccount';

const RestaurantSetting = () => {
  const [activeTab, setActiveTab] = useState('setting');
  

  return (
    <>
      <div className="w-full bg-white flex gap-x-6 items-center rounded-3xl">
        <div 
          className={`h-[45px] flex items-center ml-4 cursor-pointer relative ${activeTab === 'setting' ? 'text-orange-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('setting')}
        >
          <h1 className="text-sm">Setting</h1>
          {activeTab === 'setting' && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div 
          className={`h-[45px] flex items-center cursor-pointer relative ${activeTab === 'subscription' ? 'text-orange-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('subscription')}
        >
          <h1 className="text-sm">Subscription</h1>
          {activeTab === 'subscription' && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
        <div 
          className={`h-[45px] flex items-center cursor-pointer relative ${activeTab === 'connectedAccount' ? 'text-orange-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('connectedAccount')}
        >
          <h1 className="text-sm">Connected Account</h1>
          {activeTab === 'connectedAccount' && (
            <div className="absolute bottom-0 left-0 w-full border-b-2 border-orange"></div>
          )}
        </div>
      </div>

      {activeTab === 'setting' && <RestaurantSettingComponent />}
      {activeTab === 'subscription' && <SubcriptionSettinf />}
      {activeTab === 'connectedAccount' && <ConnectedAccount />}
    </>
  );
};

export default RestaurantSetting;
