import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    "name": "8:00 AM",
    "pv": 100,
  },
  {
    "name": "4:00 PM",
    "pv": 40,
  },
  {
    "name": "12:00 PM",
    "pv": 80,
  },
]

const AreaChartBar = () => {
  return (
    <ResponsiveContainer width="100%" height="80%">
      <AreaChart width={400} height={300} data={data}
        margin={{ left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartBar;
