import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT, FRONTEND_URL } from "src/constants/endpoints";
import CreateRestuarantHeader from "./../../../components/CreateRestuarantHeader/CreateRestuarantHeader";
import PlanCard from "./../../../components/PlanCard/PlanCard";
import CreateRestuarantFooter from "./../../../components/CreateRestuarantFooter/CreateRestuarantFooter";
import PlanTypeButton from "./../../../components/PlanTypeButton/PlanTypeButton";
import { useAxiosInterceptor } from "./../../../hooks/useAxiosInterceptor";
import { useSelector } from "react-redux";
import { BtnLoader } from "../../../commonLoader/index";
import { useAuth } from "src/contexts/auth-provider";

const Plans = () => {
  const [plans, setPlans]: any = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [selectedType, setSelectedType] = useState("MONTHLY");
  const [selectedPlan, setSelectedPlan]: any = useState(null);
  const { axBe } = useAxiosInterceptor();
  const restaurant = useSelector((state: any) => state.restaurant);
  const navigate = useNavigate();
  const { user } = useAuth();
 
  useEffect(() => {
    (async () => {
      if (!API_ENDPOINT) {
        console.error("API endpoint is not defined.");
        return;
      }
      try {
        const response = await axBe.get(
          `${API_ENDPOINT}stripe/subscription/config`
        );
        setPlans(response?.data?.prices || []);
      } catch (error) {
        setPlans([]);
      }
    })();
  }, [axBe]);

  const subscriptions = async () => {
    setInProgress(true);
    const data = restaurant?.restaurantInfo?.detailInfo;
    const restaurantInfo = {
      detailInfo: data,
      selectedPlan,
    };
    try {
      const response = await axBe.post(`${API_ENDPOINT}stripe/subscription/newSubscription`, {
        customerFirstName: user.storeUser.firstName,
        customerLastName: user.storeUser.lastName,
        customerEmail: user.storeUser.email,
        clientBaseUrl: FRONTEND_URL,
        priceId: selectedPlan?.priceId,
        storeCorrelationId: data.correlationId,
        planId: selectedPlan?.planId
      });
      localStorage.setItem('createRestaurantDetails', JSON.stringify(restaurantInfo));
      window.location.href = response.data;
    } catch (error) {
      console.error("Error subscribing:", error);
    } finally {
      setInProgress(false);
    }
  };


  const filteredPlans = (plans || [])
    ?.filter((plan: any) => plan?.frequency === selectedType)
    .sort((p1: any, p2: any) =>
      p1.price > p2.price ? 1 : p1.price < p2.price ? -1 : 0
    );
  return (
    <>
      <CreateRestuarantHeader heading={"Create Restaurant"} />
      <div className="flex flex-col justify-center items-center my-12">
        <div
          id="pricing"
          className="flex max-md:justify-center  max-md:w-[90%] "
        >
          <PlanTypeButton
            name={"Monthly"}
            type={"MONTHLY"}
            selectedType={selectedType}
            onClick={setSelectedType}
          />
          <PlanTypeButton
            name={"Yearly"}
            type={"YEARLY"}
            selectedType={selectedType}
            onClick={setSelectedType}
          />
        </div>
        <div className="flex max-lg:justify-center max-lg:gap-[15px] gap-[10px] justify-between my-8 max-lg:flex-wrap ">
          {filteredPlans?.length > 0 ? (
            filteredPlans?.map((plan: any, index: number) => (
              <PlanCard
                key={index}
                plan={plan}
                index={index}
                onSelectPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
              />
            ))
          ) : (
            <div className="flex w-full h-[100vh] justify-center">
                {plans === null
                  ? <BtnLoader/>
                  : "No plans available. :( "}
            </div>
          )}
        </div>
      </div>

      <CreateRestuarantFooter
        onCancel={() => navigate("/create-restaurant", {state: {isComingBack: true}})}
        onContinue={() => subscriptions()}
        isDisabled={!selectedPlan}
        isLoading={inProgress}
      />
    </>
  );
};

export default Plans;
