import React from "react";
import Tickicon from "../../assests/images/landing-page/tick.png";
import Checked from "../../assests/images/dashboard/Checkbox.png";

const PlanCard = ({ plan, index, onSelectPlan, selectedPlan }: any) => {
  return (
    <div
      className={`flex max-lg:w-[90%] justify-center h-[700px] w-[100%] rounded-2xl border`}
      key={index}
      style={{ borderColor: selectedPlan?.priceId === plan.priceId ? '#FD7657' : '#F1F1F2' }}
    >
      <div className="w-[90%]">
        <div key={index} className="mt-6 relative">
          <h1 className="text-lg font-medium p-2">{plan?.tier?.toUpperCase()}</h1>
          {selectedPlan?.priceId === plan.priceId && <img src={Checked} alt="" className="absolute right-0 top-0 w-[20px] h-[20px]" />}
          <p className="text-sm text-textColor p-2">
            {index === 0
              ? "For individuals or teams just getting started with project management."
              : index === 1
              ? "For teams that need to create project plans with confidence."
              : "For teams and companies that need to manage work across initiatives."}
          </p>

          <h1 className="text-lg font-medium p-2">
            $
            {plan.price}/mth
          </h1>
          <div className="flex  my-4">
            <button
              style={{ color: selectedPlan?.priceId === plan.priceId ? '#fff' : '#FD7657' }}
              className={`w-full cursor-pointer h-[48px] rounded-xl bg-[${selectedPlan?.priceId === plan.priceId ? '#FD7657' : '#ffffff'}] border border-[#F1F1F2]`} onClick={() => onSelectPlan(plan)}>
              Get Started
            </button>
          </div>
          <h1 className="text-lg font-medium p-2">
            {index === 2
              ? "Highlights of plan features:"
              : "Cloud-based Point of Sale"}
          </h1>
          <div className="text-textColor text-sm mt-4 p-1">
            {plan.features.map((feature: any, featureIndex: number) => (
              <div key={featureIndex} className="flex items-center">
                <img src={Tickicon} alt="" />
                <span className="p-1 ml-2">{feature}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
