import React from "react";
import Dropzone from "react-dropzone";
import UploadIcon from "./../../assests/images/dashboard/download.svg";

const UploadImage = ({handleFileChange,formik,image,fileError,onSkip,}: any) => {
  return (
    <Dropzone onDrop={(acceptedFiles) => handleFileChange(acceptedFiles[0])}>
      {({ getRootProps, getInputProps }) => (
        <div className="flex flex-col mb-16 min-h-[92vh] bg-white rounded-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="rounded-[12px] p-6 bg-white">
              <div className="h-[800px] flex justify-center items-center flex-col">
                <div
                  className="cursor-pointer flex justify-center items-center flex-col p-6 bg-white border-[#F1F1F2] border rounded-3xl relative"
                  {...getRootProps()}
                >
                  <div className="border-dashed border-[#c0c0c2] rounded-2xl border py-8 px-16 flex items-center flex-col">
                    <div className="mb-4">
                      <img
                        className="h-[60px] w-[60px] cursor-pointer"
                        src={UploadIcon}
                        alt="Upload Icon"
                      />
                    </div>
                    <h1 className="font-semibold mb-2">
                      Drop your image here, or{" "}
                      <span className="text-orange cursor-pointer">browse</span>
                    </h1>
                    <h1 className="text-sm text-[#666666]">
                      Support: JPG, JPEG2000, PNG
                    </h1>
                    {image && (
                      <div className="mt-4">
                        <img
                          src={image as string}
                          alt="Selected"
                          className="w-full max-h-64 object-cover rounded-lg border border-[#F1F1F2] shadow-sm"
                        />
                      </div>
                    )}
                    {fileError && (
                      <div className="text-orange text-xs mt-2">
                        {fileError}
                      </div>
                    )}
                  </div>
                  <input {...getInputProps()} />
                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.image}
                    </div>
                  ) : null}
                </div>
                <div className="mt-6">
                  <p
                    className="text-lg text-orange cursor-pointer"
                    onClick={onSkip}
                  >
                    Skip
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Dropzone>
  );
};

export default UploadImage;
