import React, { useState } from "react";
import SearchForm from "src/components/Searchform/SearchFrom";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import MenuCard from "src/components/MenuCard/MenuCard";
import Appetizers from "../../assests/images/dashboard/appetizers.png";
import Soups from "../../assests/images/dashboard/soups.png";
import Salads from "../../assests/images/dashboard/salads.png";
import Sides from "../../assests/images/dashboard/sides.png";
import Desserts from "../../assests/images/dashboard/desserts.png";
import MainCourses from "../../assests/images/dashboard/mexican.png";
import SortIcon from "../../assests/images/dashboard/swap.png";

const Menu = () => {
  const menuItems = [
    {
      image: Appetizers,
      title: "Appetizers",
      description:
        "Mozzarella - Bruschetta - Stuffed Mushrooms - Spring rolls - Chicken wings",
      count: "+4",
    },
    {
      image: Soups,
      title: "Soups",
      description:
        "Tomato Basil - Chicken Noodle - Minestrone - French Onion - Meat ball",
      count: "+3",
    },
    {
      image: Salads,
      title: "Salads",
      description:
        "Caesar - Greek - Cobb - Caprese - Garden - Nicoise - Waldorf - Spinach - Asian Chicken",
      count: "+6",
    },
    {
      image: Sides,
      title: "Sides",
      description:
        "Mashed Potatoes - Garlic bread - Streamed Vegetables - French Fries - Rice Pilaf",
      count: "+1",
    },
    {
      image: Desserts,
      title: "Deserts",
      description:
        "Chocolate Cake - Cheesecake - Apple Pie - Tiramisu - Ice Cream Sundae",
      count: "+1",
    },
    {
      image: MainCourses,
      title: "Main Courses",
      description:
        "Grilled Salmon - Beef Stroganoff - Chicken Alfredo - Lamb Chops - Vegetable Stir Fry",
      count: "+4",
    },
  ];
  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <SearchForm placeholder="Search category by name..." />
        </div>
        <div>
          <div className="flex justify-center my-4">
            <button
              className="flex items-center justify-evenly hover:bg-white
             hover:text-orange duration-500 w-[118px] text-[#FD7657] 
             h-[46px] rounded-xl border-solid border
            border-[#FD7657]"
            >
              <img className="w-[24px] h-[24px]" src={SortIcon} alt="" />
              <span>Sort by</span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-[90px]">
          <div className="h-[766px] bg-white rounded-xl flex justify-center items-center cursor-pointer">
            <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-wrap">
            {menuItems?.map((menu: any, index: any) => (
              <MenuCard key={index} menu={menu} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
