import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import DeleteIcon from "../../assests/images/dashboard/Text input container.png";
import { useDispatch, useSelector } from "react-redux";
import { delete_restaurant_modal, set_restaurants } from "src/store/features/restuarant-slice";
import { UPDATE_RESTAURANT } from "src/constants/mutations";
import { failureToast, successToast } from "src/utils/toasts";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAuth } from "src/contexts/auth-provider";

const DeleteRestaurant = ({ restaurantId }: any) => {
  const { axBe } = useAxiosInterceptor();
  const { setUser } = useAuth();
  const { filteredRestaurants, hasStoreId } = useSelector(
    (state: any) => state.restaurant
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(delete_restaurant_modal(null));
  };

  const softDeleteModal = async (restaurantId: string) => {
    const payload = {
      storeCorrelationId: restaurantId,
      isActive: false,
    };

    const variables = {
      updateStoreInput: {
        ...payload,
      },
    };

    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPDATE_RESTAURANT,
        variables,
      });

      if (data.errors) {
        const errorMessage = data.errors[0]?.message || "An error occurred";
        failureToast(errorMessage);
        return;
      }
      const updatedStores = filteredRestaurants.filter(
        (restaurant: any) => restaurant.correlationId !== restaurantId
      );
      dispatch(
        set_restaurants({restaurants: updatedStores, setUser})
      );
      dispatch(delete_restaurant_modal(null));
      successToast("Restuarant has been deleted!");
    } catch (error: any) {
      failureToast(error.response?.data?.message || error.response?.data?.detail || error.response?.data?.errors?.[0]?.message || "An unexpected error occurred");
    }
  };

  return (
    <div
      className="relative z-[9999]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-[#000000] bg-opacity-60 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-[9999] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="w-[381px] relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100 flex-col">
              <div className="bg-white flex flex-col items-center px-8 py-4">
                <div>
                  <img
                    className="h-[82px] w-[82px] mt-2"
                    src={DeleteIcon}
                    alt="Delete Icon"
                  />
                </div>
                <div className="mt-4">
                  <h1 className="text-xl text-center">Delete Restaurant</h1>
                  <p className="text-xs text-center text-[#666666] mt-1">
                    Are you sure you want to delete this restaurant? Deleting it
                    will result in the removal of all associated information!
                  </p>
                </div>
                <div className="flex gap-3 mt-4">
                  <div className="flex justify-center my-4">
                    <button
                      className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-xl bg-[#F5F5F6] hover:bg-orange hover:text-white duration-500"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>

                  <div className="flex justify-center my-4">
                    <button
                      className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-xl bg-orange hover:bg-white hover:text-orange duration-500"
                      onClick={() => softDeleteModal(restaurantId)} // Trigger deletion on click
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteRestaurant;
