import axios, {AxiosResponse} from "axios";
import {useEffect, useState} from "react";
import "react-phone-input-2/lib/style.css";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { UPLOAD_RESTAURANT_IMAGE } from "src/constants/mutations";
import { failureToast } from "../../../utils/toasts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth-provider";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateRestuarantHeader from "./../../../components/CreateRestuarantHeader/CreateRestuarantHeader";
import CreateRestuarantFooter from "./../../../components/CreateRestuarantFooter/CreateRestuarantFooter";
import UploadImage from "./../../../components/UploadImage/UploadImage";
import { useSelector } from "react-redux";

export const UploadImageRestaurant = ({ currentTabIndex, setCurrentTabIndex }: any) => {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const storeId = state?.storeId;
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const restaurant = useSelector((state: any) => state.restaurant);
  const [preSignedUrl, setPresignedUrl] = useState("");

  const formik = useFormik({
    initialValues: {
      image: "",
    },
    validationSchema: Yup.object({
      image: Yup.string().required("Image is required"),
    }),
    validateOnChange: false,
    onSubmit: () => {},
  });

  const handleFileChange = (file: any) => {
    formik.setFieldValue("image", file);
    setImage(file);
  };

  const uploadRestaurantImage = async () => {
    const data = restaurant?.restaurantInfo?.detailInfo;
    console.log('before creating the variable ', data)
    const variables = {
      createStorePreSignedUrlInput: {
        storeUserCorrelationId: user.storeUser.correlationId,
        storeName: data.name,
        fileNameExtension: "png",
        storeCorrelationId: storeId,
      },
    };

    if (!GRAPHQL_ENDPOINT) {
      console.error("GraphQL endpoint is not defined.");
      return;
    }

    try {
      const res : AxiosResponse<any, any> = await axios.post(
        GRAPHQL_ENDPOINT,
        {
          query: UPLOAD_RESTAURANT_IMAGE,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("received res == ", res);
      console.log("received res.data == ", res.data.data);

      const awsUrl = res.data.data.createUploadPreSignedUrlForStore.preSignedUrl;
      setPresignedUrl(awsUrl);

      if (res.data.errors) {
        const errorMessage = res.data.data.errors[0]?.message || "An error occurred";
        failureToast(errorMessage);
        return;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.errors?.[0]?.message ||
          "An unexpected error occurred";
        failureToast(errorMessage);
      } else {
        failureToast("An unexpected error occurred");
      }
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    try {
      console.log("presigned url ===", preSignedUrl)
      const axiosResponse =  axios.put(preSignedUrl, image, {
        headers: {
          'x-amz-acl': 'public-read'
        }
      });
      console.info(axiosResponse)
    } catch (e) {
      console.error(e)
    }
  }, [preSignedUrl]);

  const isDisabled = !formik.isValid || !formik.dirty || !!fileError || !image;

  return (
    <>
      <CreateRestuarantHeader heading={'Create Restaurant'} />
      <UploadImage handleFileChange={handleFileChange} formik={formik} image={image} fileError={fileError} onSkip={() => navigate("/plans")} />
      <CreateRestuarantFooter onCancel={() => navigate("/create-restaurant", {state: {isComingBack: true}})} isDisabled={isDisabled} onContinue={uploadRestaurantImage} />
    </>
  );
};
