import React from "react";

import { useNavigate } from "react-router-dom";
import UserIcon from "../../assests/images/dashboard/Button.png";
import BellIcon from "../../assests/images/dashboard/Notification.png";
import { useAuth } from "../../contexts/auth-provider";
import SignOut from "../../assests/images/dashboard/login.png";
import Dropdown from "../Dropdown/NotificationDropDown";

const RightHeaderSection = ({ user }: any) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  return (
    <div className="relative ">
      <div className="flex items-center gap-6">
        <span className="hidden text-right lg:block">
        </span>
        <div >
        <Dropdown />
        </div>
        <span className="h-11 w-11 rounded-lg bg-[#FEA793] flex justify-center items-center">
          <h1 className="text-white text-sm">{user?.storeUser?.firstName.slice(0,1)}{user?.storeUser?.lastName.slice(0,1)}</h1>
        </span>
        <div className="">
          <h1>{user?.storeUser?.firstName} {user?.storeUser?.lastName}</h1>
          <p className="text-sm">{user?.storeUser?.stores?.length} Restaurants</p>
        </div>
        <div className="">
          <img
            className="w-[24px] h-[24px] cursor-pointer"
            src={SignOut}
            alt=""
            onClick={() => {
              signOut();
              navigate("/");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RightHeaderSection;
