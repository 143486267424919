import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SkeletonLoader() {
  return (
    <div className="bg-white rounded-3xl w-full p-4">
      <div className="w-[100%] flex justify-between items-center gap-x-5">
        <div className="flex w-[100%] h-[200px] relative justify-between p-6 rounded-xl border border-[#F1F1F2] mb-4">
          <div className="w-full">
            <h3 className="text-lg font-semibold">
              <Skeleton width={150} />
            </h3>
            <h3 className="text-sm text-[#606360] mt-4">
              <Skeleton width={200} />
            </h3>
            <p className="text-xl font-semibold mt-2">
              <Skeleton width={100} />
            </p>
            <p className="text-[#606360] text-xs mt-2">
              <Skeleton width={250} />
            </p>
          </div>
        </div>

        <div className="flex w-[100%] h-[200px] justify-center p-6 rounded-xl border border-[#F1F1F2] mb-4">
          <div className="w-full">
            <h3 className="text-lg text-[#606360] mt-2">
              <Skeleton width={600} />
            </h3>
            <button className="w-full mt-8 px-4 py-2 bg-[white] text-orange border border-[#F1F1F2] rounded-lg flex justify-center items-center">
              <Skeleton width={150} height={36} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLoader;
