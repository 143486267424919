import React from 'react'
import { useDispatch } from "react-redux";
import { toggle_token_refresh_modal } from "src/store/features/auth-slice";

const ShowTokenRefreshModal = () => {

    const dispatch = useDispatch();

    function refreshPage(){ 
        window.location.reload(); 
      }

      const navigateToLogin = () => {
        dispatch(toggle_token_refresh_modal(false));
      };


  return (
    <div
    className="relative z-[9999]"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>

    <div className="fixed inset-0 z-[9999] overflow-y-auto">
      <div className="flex backdrop-blur min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
          <div className="flex items-center justify-center bg-gray-100">
            <div className="bg-white px-8 pt-8 pb-4 rounded-lg shadow w-full max-w-md">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Token is Refreshed
              </h2>
              <p className="text-sm text-[#616161] mb-6">
                You token was refreshed if you want to continue click on Continue if you want to refresh your page click Refresh
              </p>
              <div className="flex gap-3 mt-6">
                  <div className="flex justify-center my-4">
                    <button className="w-[100%] px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-xl bg-[#F5F5F6] hover:bg-orange hover:text-white duration-500"
                    onClick={refreshPage}
                    >
                     Refresh
                    </button>
                  </div>

                  <div className="flex justify-center my-4">
                    <button className="w-[100%] px-8 text-xs flex justify-center items-center gap-2  text-white h-[34px] rounded-xl bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500"
                    onClick={navigateToLogin}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ShowTokenRefreshModal
