import React from "react";
import DeleteIcon from "../../assests/images/dashboard/delete.png";

const MenuCard = (menu: any) => {
  return (
    <div className="xl:w-[290px] relative w-[245px] ml-3 mb-3 border-[#F1F1F2] border bg-white rounded-xl">
      <div>
        <img
          className="h-[173px] w-[100%] rounded-t-xl cursor-pointer"
          src={menu?.menu?.image}
        />
      </div>
      <div className="absolute right-2 top-2 h-[42px] w-[42px] rounded-xl bg-[#FEF5F4] flex justify-center items-center cursor-pointer">
        <img className="w-[12px] h-[13px]" src={DeleteIcon} alt="" />
      </div>

      <div className="p-4">
        <div className="flex justify-between mt-1">
          <h1>{menu.menu.title}</h1>
        </div>

        <div className="mt-1">
          <span className="text-[#A3A3A3] text-sm">
              {menu?.menu?.description}
            </span>
          <div className="inline-block m-auto ml-1">
            <span className="text-[#252525] text-[10px] px-2 py-1 bg-[#f3f3f4] rounded-xl font-medium">{menu?.menu?.count}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
