import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { SAVE_NEW_PASSWORD } from "src/constants/mutations";
import * as Yup from "yup";
import eyeIconVisible from "../../assests/images/landing-page/eye-open.png";
import eyeIcon from "../../assests/images/landing-page/visibility_off.png";
import Logo from "../../components/common/Logo";

export const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(false);

  const navigate = useNavigate();
  const routeParams = useParams();

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const showConfirmPassword = () => {
    setConfirmPasswordIcon(!confirmPasswordIcon);
  };

  const [saveNewPassword, { data, loading, error }] =
    useMutation(SAVE_NEW_PASSWORD);

  const formik = useFormik({
    initialValues: {
      newPasswordInput: "",
      confirmNewPasswordInput: "",
    },
    validationSchema: Yup.object({
      newPasswordInput: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .max(255)
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      confirmNewPasswordInput: Yup.string().oneOf(
        [Yup.ref("newPasswordInput")],
        "Passwords must match"
      ),
    }),

    onSubmit: async () => {
      const { newPasswordInput, confirmNewPasswordInput } = formik.values;
      if (!GRAPHQL_ENDPOINT) {
        console.error("GraphQL endpoint is not defined.");
        return;
      }
      saveNewPassword({
        variables: {
          saveNewPasswordInput: {
            token: routeParams.resetToken,
            newPassword: confirmNewPasswordInput,
          },
        },
      }).then((response) => {
        navigate("/dashboard");
      });

      formik.resetForm();
    },
  });


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="flex justify-center flex-col items-center">
      <div className=" mt-[5%]">
        <div className="flex ">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>
      <form className="" onSubmit={formik.handleSubmit}>
        <div className="rounded-[12px]  p-6 border border-lightGrey w-[420px] max-sm:w-[99%] mt-[50%]">
          <h1 className="text-center text-2xl mt-4 font-semibold">
            Enter your new password
          </h1>
          <div className="mt-6">
            {/* ----------- */}

            <div className="flex items-center relative">
              <input
                className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type={passwordVisible ? "text" : "password"}
                placeholder="Enter your new password"
                id="newPasswordInput"
                name="newPasswordInput"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.newPasswordInput}
              />
              <img
                onClick={togglePassword}
                className="absolute right-[10px] top-[20px] cursor-pointer"
                src={passwordVisible ? eyeIconVisible : eyeIcon}
                alt="Toggle visibility"
              />
            </div>
            {formik.touched.newPasswordInput &&
            formik.errors.newPasswordInput ? (
              <div className="text-orange text-xs mt-2 mx-1">
                {formik.errors.newPasswordInput}
              </div>
            ) : null}

            <div className="flex items-center relative">
              <input
                className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                type={confirmPasswordIcon ? "text" : "password"}
                placeholder="Confirm your new password"
                id="confirmNewPasswordInput"
                name="confirmNewPasswordInput"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmNewPasswordInput}
              />
              <img
                onClick={showConfirmPassword}
                className="absolute right-[10px] top-[20px] cursor-pointer"
                src={confirmPasswordIcon ? eyeIconVisible : eyeIcon}
                alt="Toggle visibility"
              />
            </div>
            {formik.errors.confirmNewPasswordInput &&
              formik.touched.confirmNewPasswordInput && (
                <p className="text-orange text-xs mt-2 mx-1">
                  {formik.errors.confirmNewPasswordInput}
                </p>
              )}

            <div className="flex justify-center my-4">
              <button
                type="submit"
                className={`w-[100%] text-white h-[40px] rounded-xl bg-[#FD7657]
                      ${
                        (!formik.isValid || !formik.dirty) &&
                        "opacity-[50%] pointer-events-none"
                      }
                    `}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
