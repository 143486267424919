import React from 'react';

const RestaurantSettingIcon = ({ color = "#828282" }) => (
  <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.83317 16.1673C2.30609 16.1673 1.85487 15.9796 1.47952 15.6043C1.10418 15.2289 0.916504 14.7777 0.916504 14.2507V2.75065C0.916504 2.22357 1.10418 1.77235 1.47952 1.39701C1.85487 1.02166 2.30609 0.833984 2.83317 0.833984H18.1665C18.6936 0.833984 19.1448 1.02166 19.5201 1.39701C19.8955 1.77235 20.0832 2.22357 20.0832 2.75065V14.2507C20.0832 14.7777 19.8955 15.2289 19.5201 15.6043C19.1448 15.9796 18.6936 16.1673 18.1665 16.1673H2.83317ZM2.83317 14.2507H18.1665V2.75065H2.83317V14.2507ZM9.5415 13.2923H11.4582L11.7457 11.8548C11.9373 11.775 12.117 11.6911 12.2847 11.6033C12.4524 11.5154 12.6241 11.4076 12.7998 11.2798L14.1894 11.7111L15.1478 10.0819L14.0457 9.12357C14.0776 8.91593 14.0936 8.70829 14.0936 8.50065C14.0936 8.29301 14.0776 8.08537 14.0457 7.87773L15.1478 6.9194L14.1894 5.29023L12.7998 5.72148C12.6241 5.59371 12.4524 5.48589 12.2847 5.39805C12.117 5.3102 11.9373 5.22635 11.7457 5.14648L11.4582 3.70898H9.5415L9.254 5.14648C9.06234 5.22635 8.88265 5.3102 8.71494 5.39805C8.54723 5.48589 8.37553 5.59371 8.19984 5.72148L6.81025 5.29023L5.85192 6.9194L6.954 7.87773C6.92206 8.08537 6.90609 8.29301 6.90609 8.50065C6.90609 8.70829 6.92206 8.91593 6.954 9.12357L5.85192 10.0819L6.81025 11.7111L8.19984 11.2798C8.37553 11.4076 8.54723 11.5154 8.71494 11.6033C8.88265 11.6911 9.06234 11.775 9.254 11.8548L9.5415 13.2923ZM10.4998 10.4173C9.97275 10.4173 9.52154 10.2296 9.14619 9.8543C8.77084 9.47895 8.58317 9.02773 8.58317 8.50065C8.58317 7.97357 8.77084 7.52235 9.14619 7.14701C9.52154 6.77166 9.97275 6.58398 10.4998 6.58398C11.0269 6.58398 11.4781 6.77166 11.8535 7.14701C12.2288 7.52235 12.4165 7.97357 12.4165 8.50065C12.4165 9.02773 12.2288 9.47895 11.8535 9.8543C11.4781 10.2296 11.0269 10.4173 10.4998 10.4173Z"
      fill={color}
    />
  </svg>
);

export default RestaurantSettingIcon;
