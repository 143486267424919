import React from 'react';

interface IconProps {
  color?: string; // Optional color prop
}

const ComboIcon: React.FC<IconProps> = ({ color = '#828282' }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6594 11.375C11.1927 10.7188 10.5766 10.263 9.81094 10.0078C9.04531 9.7526 8.25417 9.625 7.4375 9.625C6.62083 9.625 5.82969 9.7526 5.06406 10.0078C4.29844 10.263 3.68229 10.7188 3.21563 11.375H11.6594ZM0.875 13.125C0.875 11.5354 1.53854 10.263 2.86563 9.30781C4.19271 8.3526 5.71667 7.875 7.4375 7.875C9.15833 7.875 10.6823 8.3526 12.0094 9.30781C13.3365 10.263 14 11.5354 14 13.125H0.875ZM0.875 16.625V14.875H14V16.625H0.875ZM15.75 20.125V18.375H16.975L18.2 6.125H9.84375L9.625 4.375H14V0.875H15.75V4.375H20.125L18.6812 18.8563C18.6375 19.2208 18.4771 19.5234 18.2 19.7641C17.9229 20.0047 17.6021 20.125 17.2375 20.125H15.75ZM1.75 20.125C1.50208 20.125 1.29427 20.0411 1.12656 19.8734C0.958854 19.7057 0.875 19.4979 0.875 19.25V18.375H14V19.25C14 19.4979 13.9161 19.7057 13.7484 19.8734C13.5807 20.0411 13.3729 20.125 13.125 20.125H1.75Z"
        fill={color}
      />
    </svg>
  );
};

export default ComboIcon;
