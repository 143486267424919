import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function UpcomingInvoiceSkeleton() {
    return (
        <div className="bg-white rounded-3xl px-6 py-4 mt-8">
            <section>
                <div className="flex items-center gap-x-4">
                    <h3 className="text-lg font-medium">
                        <Skeleton width={150} />
                    </h3>
                    <p className="text-[#606360] text-xs">
                        <Skeleton width={100} />
                    </p>
                </div>

                <div className="flex justify-between bg-gray-100 mt-6 rounded-lg h-[40px] border-b border-[#EBF5FF]">
                    <p className="text-sm">
                        <Skeleton width={200} />
                    </p>
                    <p className="text-[#606360] text-sm">
                        <Skeleton width={80} />
                    </p>
                </div>

                <div className="flex justify-between bg-gray-100 mt-4 rounded-lg">
                    <p className="text-lg font-medium">
                        <Skeleton width={200} />
                    </p>
                    <p className="text-right mt-2 text-lg font-medium">
                        <Skeleton width={80} />
                    </p>
                </div>
            </section>
        </div>
    )
}

export default UpcomingInvoiceSkeleton
