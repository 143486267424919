import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBack from "../../assests/images/landing-page/arrow_back.png";
import Logo from "../../components/common/Logo";

export const RecoverPassword = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center flex-col items-center gap-64">
      <div className=" mt-[5%]">
        <div className="flex ">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>

      <div className="max-sm:mt-[5%] w-[420px] rounded-[12px] mt-[0%] p-6 border border-lightGrey">
        <h1 className="text-center text-2xl mt-2 font-semibold">Email Sent!</h1>
        <p className="text-sm text-[#9B9B9B] text-center mt-4">
          {/* We sent a password reset link to{" "} */}
          <span>
            {" "}
            If you have registered an account with us, a password reset link
            will be sent to the provided email shortly
          </span>
        </p>
        <p className="text-orange text-sm text-[#9B9B9B] text-center mt-4">
          Didn’t receive the email? Click to resend
        </p>

        <div className="mt-7">
          <div className="flex justify-center my-4">
            <button
              onClick={() => navigate("/forgot-password")}
              className="w-[100%] max-sm:w-[90%] text-black h-[40px] rounded-xl bg-[#F5F5F6]"
            >
              Resend Email
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center absolute bottom-12 cursor-pointer">
        <div className="mr-2">
          <img src={ArrowBack} alt="" />
        </div>
        <Link to="/login">
          <h1 className="text-orange">Back To Login</h1>
        </Link>
      </div>
    </div>
  );
};
