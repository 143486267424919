import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useDispatch } from "react-redux";
import { GET_ALL_RESTAURANTS } from "src/constants/queries";
import { useAxiosInterceptor } from "./useAxiosInterceptor";
import { set_restaurants } from "src/store/features/restuarant-slice";
import { useAuth } from "src/contexts/auth-provider";

export const useRestaurant = () => {
  const { axBe } = useAxiosInterceptor();
  const { setUser } = useAuth();
  const dispatch = useDispatch();

  const fetchRestaurants = async () => {
    try {
      const response = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_ALL_RESTAURANTS,
      });

      // Access the array from the response data
      const fetchedRestaurants = response.data?.data?.loggedInUserStores;

      if (Array.isArray(fetchedRestaurants)) {
        dispatch(set_restaurants({restaurants: fetchedRestaurants, setUser}));
      } else {
        console.error("Unexpected result structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return { fetchRestaurants };
};
