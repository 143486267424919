import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateRestuarantHeader from "../../../components/CreateRestuarantHeader/CreateRestuarantHeader";
import CreateRestuarantFooter from "../../../components/CreateRestuarantFooter/CreateRestuarantFooter";
import { useAxiosInterceptor } from "../../../hooks/useAxiosInterceptor";
import { API_ENDPOINT } from "src/constants/endpoints";
import SuccessCard from "src/components/SuccessCard/SuccessCard";
import { BtnLoader } from "../../../Loader/index";
import { useRestaurant } from "src/hooks/useRestaurant";
import ConnectStripSection from "src/components/ConnectStripSection/ConnectStripSection";
import ExistingConnectStripSection from "src/components/ExistingConnectStripSection/ExistingConnectStripSection";

const Success = () => {
  const [isStarterPlan, setIsStarterPlan] = useState(false);
  const [existingAccountInProgress, setExistingAccountInProgress] =
    useState(false);
  const { axBe } = useAxiosInterceptor();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const { fetchRestaurants } = useRestaurant();
  const [existingConnectedAccounts, setExistingConnectedAccounts] = useState<any>([]);

  useEffect(() => {
    try {
      sessionId &&
        (async () =>
          await axBe.get(
            `${API_ENDPOINT}stripe/subscription/checkoutSession?sessionId=${sessionId}`
          ))();
      const { selectedPlan } = JSON.parse(
        localStorage.getItem("createRestaurantDetails") || "{}"
      );
      if (selectedPlan.tier === "starter") {
        setIsStarterPlan(true);
      }
    } catch (err) {}
    fetchRestaurants();
  }, [axBe, sessionId]);

  useEffect(() => {
    (async () => {
      try {
        setExistingAccountInProgress(true);
        const response = await axBe.get(
          `${API_ENDPOINT}stripe/connectedAccount/existingConnectedAccounts`
        );
        setExistingConnectedAccounts(response?.data || []);
        console.log(response?.data);
        
      } catch (error) {
        setExistingConnectedAccounts([]);
      } finally {
        setExistingAccountInProgress(false);
      }
    })();
  }, [axBe]);

  const onHanldeDone = () => {
    localStorage.removeItem("createRestaurantDetails");
    navigate("/my-restaurants");
  };

  const toggleExistingAccountConnect = (index: number, value: boolean) => {
    const tempAccounts = [...existingConnectedAccounts];
    tempAccounts[index].inProgress = value;
    setExistingConnectedAccounts(tempAccounts);
  };

  const address = `${existingConnectedAccounts?.supportAddress?.city || '--'}, ${existingConnectedAccounts?.supportAddress?.country ? existingConnectedAccounts?.supportAddress?.line1 + ', ' : ''}${existingConnectedAccounts?.supportAddress?.line2}, ${existingConnectedAccounts?.supportAddress?.state}, ${existingConnectedAccounts?.supportAddress?.postalCode}`;

  return (
    <>
      <CreateRestuarantHeader heading={"Create Restaurant"} />
      {isStarterPlan ? (
        <SuccessCard
          onHanldeDone={onHanldeDone}
          heading="Your subcription has been enabled successfully"
          subHeading="We have sent you a confirmation code to your email address"
        />
      ) : (
        <>
          <ConnectStripSection />
          {existingAccountInProgress ? (
            <div className="flex w-full justify-center items-center">
              <BtnLoader />
            </div>
          ) : existingConnectedAccounts.length ? (
            <ExistingConnectStripSection existingConnectedAccounts={existingConnectedAccounts} toggleExistingAccountConnect={toggleExistingAccountConnect} />
          ) : (
            <></>
          )}
        </>
      )}
      {!isStarterPlan && (
        <CreateRestuarantFooter
          onCancel={null}
          isDisabled={false}
          onContinue={onHanldeDone}
          primaryTextButton={"Done"}
        />
      )}
    </>
  );
};

export default Success;
