import * as React from "react";

interface SVGComponentProps {
  color?: string; // Optional color prop
  [key: string]: any; // Allows additional props to be passed
}

const SVGRestuarantComponent: React.FC<SVGComponentProps> = ({ color = "#828282", ...props }) => (
  <svg
    width={14}
    height={19}
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.125 18.25V10.2438C2.38125 10.0396 1.75781 9.63125 1.25469 9.01875C0.751563 8.40625 0.5 7.69167 0.5 6.875V0.75H2.25V6.875H3.125V0.75H4.875V6.875H5.75V0.75H7.5V6.875C7.5 7.69167 7.24844 8.40625 6.74531 9.01875C6.24219 9.63125 5.61875 10.0396 4.875 10.2438V18.25H3.125ZM11.875 18.25V11.25H9.25V5.125C9.25 3.91458 9.67656 2.88281 10.5297 2.02969C11.3828 1.17656 12.4146 0.75 13.625 0.75V18.25H11.875Z"
      fill={color} // Use the color prop
    />
  </svg>
);

export default SVGRestuarantComponent;
