import DashboardIcon from "../../icons/DashboardIcon";
import RestuarantIcon from "../../icons/RestuarantIcon";
import RestuarantSettingIcon from "../../icons/RestaurantSetting";
import EmployeeIcon from "../../icons/EmployeeIcon";
import MenuIcon from "../../icons/MenuIcon";
import ComboIcon from "../../icons/ComboICon";

export const ownerSidebar = [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon
  },
  {
    url: '/my-restaurants',
    name: 'My Restaurants',
    icon: RestuarantIcon
  },
];

export const restaurantSidebar = [
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/dashboard`,
    name: 'Dashboard',
    icon: DashboardIcon
  },
  {
    url: '/restaurant-setting',
    name: 'Restaurant Settings',
    icon: RestuarantSettingIcon
  },
  {
    url: '/employee',
    name: 'Employee',
    icon: EmployeeIcon
  },
  {
    url: '/menu',
    name: 'Menu',
    section: 'Manage',
    isSubMenuOpen: false,
    icon: MenuIcon,
    children: [
      {
        url: '/appetizers',
        name: 'Appetizers',
      },
      {
        url: '/soups',
        name: 'Soups',
      },
      {
        url: '/salads',
        name: 'Salads',
      },
      {
        url: '/desserts',
        name: 'Desserts',
      },
    ]
  },
  {
    url: '/combo',
    name: 'Combo',
    icon: ComboIcon
  },
];
