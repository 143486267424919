import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function TimePickerSkeletonLoader() {
    return (
        <div className="flex flex-col w-[100%] mt-3">
            {/* Placeholder for time items */}
            {[...Array(7)].map((_, index) => (
                <div
                    key={index}
                    className={`flex justify-between items-center pb-[19px] gap-20 my-2 h-[43px] border-b border-[#F7F7F7]`}
                >
                    {/* Day and Checkbox Placeholder */}
                    <div className="flex gap-36 items-center">
                        <Skeleton width={50} height={20} />
                        <div className="flex items-center gap-2">
                            <Skeleton circle={true} height={20} width={20} />
                            <Skeleton width={60} height={20} />
                        </div>
                    </div>
                    {/* Time Picker Placeholder */}
                    <div className="w-[300px] flex gap-8 items-center">
                        <div className="w-[120px]">
                            <Skeleton height={40} />
                        </div>
                        <div className="w-[120px]">
                            <Skeleton height={40} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TimePickerSkeletonLoader;
