import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restaurants: [],
  filteredRestaurants: [],
  hasStoreId: null,
  restaurantInfo: {
    detailInfo: {},
  },
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    add_restaurant: (state, { payload }) => {
      state.restaurantInfo.detailInfo = payload;
    },
    set_restaurants: (state, { payload }) => {
      const parsedUser = JSON.parse(localStorage.getItem('user') || 'null');
      if(parsedUser) {
        parsedUser.storeUser.stores = payload.restaurants;
        if (payload?.setUser) payload?.setUser(parsedUser);
        localStorage.setItem('user', JSON.stringify(parsedUser));
      }
      state.restaurants = payload.restaurants;
      state.filteredRestaurants = payload.restaurants;
    },
    search_restaurants: (state, { payload }) => {
      state.filteredRestaurants = state.restaurants.filter((restaurant: any) =>restaurant.name.toLowerCase().includes(payload.toLowerCase()));
    },
    delete_restaurant_modal: (state :any, { payload }) => {
      state.hasStoreId = payload;
    },
  },
});

export const { add_restaurant ,delete_restaurant_modal, set_restaurants,search_restaurants } = restaurantSlice.actions;

export default restaurantSlice.reducer;
