import React, { useState } from "react";
import DeleteIcon from "../../assests/images/dashboard/delete 2.png";
import SettingIcon from "../../assests/images/dashboard/settings.png";
import MassegeIcon from "../../assests/images/dashboard/Container 2.png";
import MassegeIcon1 from "../../assests/images/dashboard/Vertical container (1).png";
import MassegeIcon2 from "../../assests/images/dashboard/Vertical container 2.png";
import MassegeIcon3 from "../../assests/images/dashboard/Vertical container.png";
import BellIcon from "../../assests/images/dashboard/Notification.png";
import NotificationScilentDropDown from "./NotificationScilentDropDown";


const NotificationDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  const toggleDropdown = (value?: string) => {
    setIsOpen(!isOpen);
    if (value) setValue(value);
  };

  return (
    <div className="relative  text-left">
      <div>
        <img
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => toggleDropdown()}
          className="cursor-pointer"
          src={BellIcon}
          alt=""
        />
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-[30rem] origin-top-right rounded-2xl bg-white shadow-lg ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="flex items-center justify-between px-4">
           <h1 className="text-lg font-semibold">Notification</h1>
           <NotificationScilentDropDown/>
          </div>
          <div className="py-1 px-2" role="none">
            <div className="flex py-1 px-4 gap-x-4">
              <div className="flex items-center gap-x-2">
                <img className="w-[6px] h-[6px]" src={MassegeIcon} alt="" />
                <img className="w-[32px] h-[32px]" src={MassegeIcon1} alt="" />
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-xs">
                  Your restaurant ‘Whispering Willow’ has achieved total sales
                  of $5,432.87 today
                </p>
                <p className="text-xs text-[#9B9B9B]">2 min ago</p>
              </div>
            </div>
            <div className="flex py-1 px-4 gap-x-4">
              <div className="flex items-center gap-x-2">
                <img className="w-[6px] h-[6px]" src={MassegeIcon} alt="" />
                <img className="w-[32px] h-[32px]" src={MassegeIcon2} alt="" />
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-xs">
                  Your restaurant ‘Whispering Willow’ has achieved total sales
                  of $5,432.87 today
                </p>
                <p className="text-xs text-[#9B9B9B]">2 min ago</p>
              </div>
            </div>
            <div className="flex py-1 px-4 gap-x-4">
              <div className="flex items-center gap-x-2">
                <img className="w-[6px] h-[6px]" src={MassegeIcon} alt="" />
                <img className="w-[32px] h-[32px]" src={MassegeIcon3} alt="" />
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-xs">
                  Your restaurant ‘Whispering Willow’ has achieved total sales
                  of $5,432.87 today
                </p>
                <p className="text-xs text-[#9B9B9B]">2 min ago</p>
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDropDown;
