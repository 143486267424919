import { Navigate } from "react-router-dom";
import { RouteInterface } from "./authenticated-routes";
// import LandingPage from "../pages/landing-page";
import { Login } from "../pages/auth/Login";
import { RecoverPassword } from "../pages/auth/RecoverPassword";
import { ResetPassword } from "../pages/auth/ResetPassword";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { SignUp } from "../pages/auth/SignUp";
import { UserAccountRegistered } from "../pages/auth/UserAccountRegistered";
import { ActivateAccount } from "../pages/auth/ActivateAccount";

const routesForNonAuth: RouteInterface[] = [
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/password-reset-email-sent",
    element: <RecoverPassword />,
  },
  {
    path: "/account-registered",
    element: <UserAccountRegistered />,
  },
  {
    path: "/reset-password/:resetToken",
    element: <ResetPassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/activate-account/:verificationCode",
    element: <ActivateAccount />,
  },
];

export default routesForNonAuth;
