import React from "react";
import SuccessImg from "./../../assests/images/dashboard/successIcon.png";

const SuccessCard = ({ onHanldeDone, heading, subHeading }: any) => {
  return (
    <div className="flex justify-center items-center w-full h-[85vh]">
    <div className="bg-white  flex justify-center items-center flex-col rounded-2xl py-8 px-20">
      <div>
        <img src={SuccessImg} alt="" />
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-medium text-center">
          {heading}
        </h1>
      </div>
      <div className="mt-2">
        {subHeading && <p className="text-sm">{subHeading}</p>}
      </div>
      <div>
        <div className="mt-4">
          <button
            className="text-white h-[40px] rounded-xl bg-orange border border-[#F1F1F2]  px-4"
            onClick={onHanldeDone}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SuccessCard;
