import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showExpiryLoginModal: false,
  isShowTokenRefreshModal: false,
  isLoading: false,
  isLoggedIn: false,
  user: {},
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    add_user: (state, { payload }) => {},
    toggle_login_expiry_modal: (state, { payload }) => {
      state.showExpiryLoginModal = payload;
    },
    toggle_token_refresh_modal: (state, { payload }) => {
      state.isShowTokenRefreshModal = payload;
    },
  },
});

export const { add_user, toggle_login_expiry_modal, toggle_token_refresh_modal } = authSlice.actions;

export default authSlice.reducer;
