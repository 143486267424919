import React from 'react';
import Logo from "./../common/Logo";
import { useNavigate } from 'react-router-dom';

const CreateRestuarantHeader = ({ heading }: {heading: string}) => {
  const navigate = useNavigate();
  return (
    <div className="sticky top-[0px] bg-white z-50">
    <div className="border-borderGray border flex justify-between px-4 py-2">
      <div className="flex items-center cursor-pointer" onClick={() => navigate("/dashboard")}>
        <Logo />
        <span className="pl-3 text-2xl">EatsBits</span>
      </div>
      <div className="p-3 mr-10">
        <h1 className="text-2xl text-center">{heading}</h1>
      </div>
    </div>
  </div>
  );
};

export default CreateRestuarantHeader;
