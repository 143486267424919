import React from "react";
import CrossIcon from "../../assests/images/dashboard/cancleCrossicon.png";

const ErrorCard = ({ heading, subHeading, onHanldeSecondaryBtn, secondaryButtonText }: any) => {
  return (
    <div className="flex justify-center items-center w-full h-[85vh]">
    <div className="bg-white border border-[#F1F1F2] flex justify-center items-center flex-col rounded-2xl py-8 px-20">
      <div>
        <img className="w-[50px] h-[50px]" src={CrossIcon} alt="" />
      </div>
      <div className="mt-8">
        <h1 className="text-2xl font-medium text-center">
          {heading}
        </h1>
      </div>
      <div className="mt-2">
        <p className="text-sm">
          {subHeading}
        </p>
      </div>
      <div>
        <div className="mt-4">
          <button
            className="text-white h-[40px] rounded-xl bg-orange border border-[#F1F1F2]  px-4"
            onClick={onHanldeSecondaryBtn}
          >
            {secondaryButtonText|| 'Cancle'}
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ErrorCard;
