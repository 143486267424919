export const PLANS = `query plans {
    plans{
      name,
      monthlyPriceMonthlyBilled,
      monthlyPriceAnnuallyBilled,
      features
    }
  }`;

  export const GET_ALL_RESTAURANTS = `query loggedInUserStores{
    loggedInUserStores{
      correlationId
      name
      bucketKeyName
      employeeCount
      address{
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }`;

  export const RESTAURANT_SUBSCRIPTION_TAB_DATA = `
  query restaurantSubscriptionTabData($restaurantSubscriptionTabDataInput: RestaurantSubscriptionTabDataInput!) {
    restaurantSubscriptionTabData(restaurantSubscriptionTabDataInput: $restaurantSubscriptionTabDataInput) {
      customerPortalUrl
      eatsbitsSubscription{
      stripeSubscriptionId
      planId
      currentPeriodStart
      currentPeriodEnd
      status
    }
      invoices{
      stripeInvoiceNumber
      invoiceCreatedAtByStripe
      status
      periodStart
      periodEnd
      hostedInvoiceUrl
      nextPaymentAttempt
      totalExcludingTax
      description
    }
      subscribedPlan{
      planId
      tier
      frequency
      price
      productName
    }
      upcomingInvoice {
      stripeInvoiceNumber
      invoiceCreatedAtByStripe
      description
      status
      totalExcludingTax
      periodStart
      periodEnd
      nextPaymentAttempt
      hostedInvoiceUrl
    }
    }
  }
`;

export const STORES = `
  query stores($retrieveStoresInput: RetrieveStoresInput!) {
    stores(retrieveStoresInput: $retrieveStoresInput) {
      correlationId
      name
      nonEnglishName
      primaryContactPhoneNumber
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      style
      employeeCount
      connectedAccountId
      bucketKeyName
      categories {
        correlationId
        name
      }
      operationHours {
        day
        startHour
        endHour
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;


  